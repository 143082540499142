/**
=========================================================
* MAAN Portal React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// MAAN Portal React components
import MDBox from "components/MDBox";
// MAAN Portal React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import MDSnackbar from "components/MDSnackbar";
// react-router-dom components
import "./style.css";
// @mui material components
import Card from "@mui/material/Card";
// MAAN Portal React components
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
// import { SnackbarProvider, useSnackbar } from 'notistack';
// Authentication layout components

// Images

// Overview page components

// Data
// Images

function CreateRole() {
  const succesMessage = "Role created successfully"
  const errorMessage = "An error occured try again"
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

const closeErrorSB = () => setErrorSB(false);
const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="AMJN E-CARD"
      content= {succesMessage}
      dateTime="11 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="AMJN E-CARD"
      content= {errorMessage}
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );


  const handleClick = async () => {
    
    const roleName = document.querySelector("#roleName")
    const description = document.querySelector("#description")
    const roleNameValue = roleName.value;
    const descriptionValue = description.value;
    const data = {
      "name": roleNameValue,
      "code": descriptionValue
    };
    const fetchResponse = await fetch(`https://cardapi.ahmadiyyanigeria.net/api/user/addrole`, {
      method: 'POST',
      body:JSON.stringify(data)
    });
    if(fetchResponse.status == "200")
    {
      setSuccessSB(true)
      window.location.href = "/members"
    }
    else{
      setErrorSB(true);
    }
   
    
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Grid item xs={12} sm={6} lg={3}>
        {renderSuccessSB}
        {renderErrorSB}
      </Grid>
      <div className="card-container">
      <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Create Zone
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput type="text" label="Name" id="roleName" fullWidth />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="text" label="Description" id="description" fullWidth />
              </MDBox>
              
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth  onClick={handleClick}>
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </div>
    </DashboardLayout>
  );
}

export default CreateRole;
