import './style.css'
import logo from "./amjnLogo.png"
import mkanlogo from "./mkan.png"
import maanlogo from "./maan.png"
import limanlogo from "./liman.png"
import sign from "./signature.png"
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print'
import { useRef } from 'react'
import Button from '@mui/material/Button';

import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from 'react'

let IdCard = (props) =>{
    
  const succesMessage = "Successfully approved"
  const errorMessage = "An error occured pls try again"
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

const closeErrorSB = () => setErrorSB(false);
const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="AMJN E-CARD"
      content= {succesMessage}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="AMJN E-CARD"
      content= {errorMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

   const cardInfo = props.info;
   const isPrint = props.isPrint;
  //  const auxillaryBodyName = props.info.auxillaryBodyName;
   console.log(cardInfo);
  //  console.log(auxillaryBodyName);
   const componentRef = useRef();
   const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data',
    onAfterPrint : async () => 
    {
        const fetc = await fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/cards/printcardrequest?cardId=${cardInfo.id}`, {
            method : "PUT"
        });
        if(fetc.status == "200")
        {
            setTimeout(() => {
                setSuccessSB(true)
                window.location.href = "/activecard"
            }, 1000)
            
        }
        else{
            setErrorSB(true);
        }

    }
   });
   const level = localStorage.getItem("level");
  return ( cardInfo != null ? (
    <div>
        <Grid item xs={12} sm={6} lg={3}>
        {renderSuccessSB}
        {renderErrorSB}
      </Grid>
        <div class="container" ref={componentRef}>
            <div class="padding">
                <div class="font id-size">
                    <div class="top">
                   
                      <div className=''>
                        <img className='id-logo' src={logo} alt="logo" width="2rem"/>
                        <p className='ahm'><strong>AHMADIYYA</strong> <br/> <strong>MUSLIM JAMA'AT</strong> <br /> <span><strong>OF NIGERIA</strong></span></p>
                    </div>
                        <img className='id-img' src={`https://cardapi.ahmadiyyanigeria.net/Images/${cardInfo.photo}`} alt="card"/>
                    </div>
                    <div class="bottom">
                        <p style={{fontSize:"19px", fontFamily:'math', fontWeight:'bold'}}>{cardInfo.lastName.toLowerCase()} {cardInfo.firstName.toLowerCase()}</p>
                        <p id='cardNumber' hidden>{cardInfo.id}</p>
                        <p class="no"> {cardInfo.jamaatName.toLowerCase()} Jama'at, {cardInfo.circuitName.toLowerCase()}</p>
                            <div style={{marginTop:"12px"}}>
                            <p style={{fontSize:"14px", fontFamily:'math', fontWeight:'bold'}}>Membership No: {cardInfo.memberNumber}</p>
                            {(cardInfo.wasiyatNo == 0) ? "" :
                               <p style={{fontSize:"14px", fontFamily:'math', fontWeight:'bold'}}>Wasiyat No: {cardInfo.wasiyatNo}</p>
                            }
                           
                            </div>
                        <div className='end'>
                               {(cardInfo.requestStatus == 1) ? "" :    <QRCode  className='qr-des' value={`https://card.ahmadiyyanigeria.net/profile/public?id=${cardInfo.memberNumber}`} level="H" />}
                        </div>
                    </div>
                </div>
            </div>
            <div>
            <div style={{position: "relative"}} class="font id-size">
                    <div class="top">
                   
                      <div className=''>
                        <p className='back-head-info'>The holder whose name and photograph </p>
                        <p className='back-head-info-2'>appears overleaf is a member of</p>
                       
                    </div >
                          {/* {(cardInfo.wasiyatNo === "Lajna") ? "" :
                              <img className='id-back-logo' src={limanlogo} alt="logo" width="2rem"/>
                         } */}
                         {(cardInfo.wasiyatNo == 0) ? "" :
                              <img className='id-back-logo' src={mkanlogo} alt="logo" width="2rem"/>
                         }
                         {/* {(cardInfo.wasiyatNo == 0) ? "" :
                              <img className='id-back-logo' src={maanlogo} alt="logo" width="2rem"/>
                         } */}
                         
                         {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back'><strong>MAJLIS KHUDDAM-UL-AHMADIYYA NIGERIA</strong> <br /></p>
                         }
                          {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-khu-2'><span><strong>(Ahmadiyya Muslim Youth Organization)</strong></span></p>
                         }
                         {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-2'><span><strong>AN AFFILIATE OF NATIONAL YOUTH COUNCIL OF NIGERIA</strong></span></p>
                         }
                        {/* {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-maan'><strong>MAJLIS ANSARULLAH NIGERIA</strong> <br /></p>
                         }
                         {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-maan-2'><span><strong>(Ahmadiyya Muslim Elders Association)</strong></span></p>
                         }
                          {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-maan-3'><span><strong className='ahm-back-mr-2'>DILA: AGEGE</strong><strong>MUQAM: OJOKORO</strong></span></p>
                         } */}

                          {/* {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-maan'><strong>LAJNA IMAILLAH NIGERIA</strong> <br /></p>
                         }
                         {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-laj-2'><span><strong>(Women servant of Allah)</strong></span></p>
                         }
                          {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-laj-3'><span><strong className='ahm-back-mr-2'>REGION: 1</strong><strong className='ahm-back-mr-2'>DISTRICT: OJOKORO</strong><strong>MUQAM: OJOKORO</strong></span></p>
                         } */}
                          {(cardInfo.wasiyatNo == 0) ? "" :
                               <p className='ahm-back-laj-4'><span><strong className='ahm-back-mr-2'>BLOOD GROUP: O+</strong><strong>GENOTYPE: AA</strong></span></p>
                         }
                         {/* <p className='ahm-back'><strong>AHMADIYYA MUSLIM JAMA'AT</strong> <br /> </p> */}
                         {/* <p className='ahm-back-2'><span ><strong>NIGERIA</strong></span></p> */}
                         
                         
                       
                    </div>
                    <div className='mid-info'>
                              <p style={{fontSize:"13px", fontFamily:'math',fontWeight:"600", marginBottom:'-7px'}} >Km 27, Lagos-Abeokuta Expressway,</p>
                              <p style={{fontSize:"13px", fontFamily:'math',fontWeight:"600", marginBottom:'5px'}} >Ojokoro, Via Agege, Abule Egba, Lagos</p>
                              <p style={{fontSize:"12px", fontFamily:'math',}} className=''>ahmadiyya.ng.hq@gmail.com  <br></br><span>+2347064290096</span></p>
                    </div>
                        {/* <div className='end-back'>
                               {(cardInfo.requestStatus == 1) ? "" :    <QRCode  className='qr-des-back' value= {`cardInfo.memberNumber`} level="H" />}
                        </div> */}
                    <div class="bottom-back">           
                        <p style={{fontSize:"12px", fontFamily:'math', marginBottom:"-7px"}}>If found, please kindly bring to the nearest </p>
                        <p style={{fontSize:"12px", fontFamily:'math',}}>Police Station or contact the above address</p> 
                        <p class="sig"> Authorized Signature </p>
                        <hr className='underline'/>
                        <img className='sig-img' src={sign} alt="signature"/>
                        <p style={{top: "-1px"}} class="gensec"> General Secretary</p>
                        
                    </div>
                </div>
            </div>
        </div>
        {
            isPrint == false && level == "national" ? <Button variant="contained" size="medium" className="req" onClick={handlePrint}>
            Print Card
          </Button> : ""
        }
        
    </div>)
    : "")
}
export default IdCard;