/**
=========================================================
* AMJN E-CARD  React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 AMJN (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// AMJN E-CARD  React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// AMJN E-CARD  React example components
import { useEffect, useState } from 'react'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import IdCard from "examples/IdCard/idcard"
import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
import "./style.css";
import Header from "layouts/profile/components/Header";
import Button from '@mui/material/Button';
//'https://cardapi.ahmadiyyanigeria.net/api/v1/cards/approve/e939f21c-63f5-483d-9f07-b9888444d0f5'
function IdCardView() {

  const[message, setMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

const closeErrorSB = () => setErrorSB(false);
const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="AMJN E-CARD"
      content= {message}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="AMJN E-CARD"
      content= {message}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const level = localStorage.getItem("level");
  const memberNo = window.location.href.split("=")[1];
  const urlGet = window.location.href.split("?")[1];
  const splitUrl = urlGet.split("=")[0];
  console.log(splitUrl);
  const [cardInfo, setCardInfo] = useState(null);
    useEffect(() => {
        
            fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/cards/getcurrentcardrequestbymembernumber?memberNo=${memberNo}`)
            .then(x => x.json()).then(response => 
            {
                console.log(response);
                setCardInfo(response);
            });
    }, []);

  let handleClick = async () => {
    const settings = {
      method: 'PUT',
  };
  try {
    const fetchResponse = await fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/cards/approve/${cardInfo.id}`, settings);
    if(fetchResponse.status == "200")
    {
      setMessage("Card request approved successfully")
      setSuccessSB(true)
      window.location.href = "/cardrequests"
    }
    else{
      setMessage("An error occured! Try again")
      setErrorSB(true);
    }
} catch (e) {
  console.log(e);
    return e;
}    
  }
  let handleCancelClick = async () => {
    const cancelReason = prompt("Rejection reason");
      try
      {
        const settings = {
          method: 'PUT',
        };
        const fetchResponse = await fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/cards/reject/${cardInfo.id}?rejectionReason=${cancelReason}`, settings);
        if(fetchResponse.status == "200")
        {
          setMessage("Card request cancelled successfully")
          setSuccessSB(true)
          window.location.href = "/cardrequests"
        }
        else{
          setMessage("Cancelling card request failed")
          setErrorSB(true);
        }
      } 
    catch (e)
    {
      console.log(e);
        return e;
    }    
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item xs={12} sm={6} lg={3}>
        {renderSuccessSB}
        {renderErrorSB}
      </Grid>
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
           <IdCard info={cardInfo} isPrint={splitUrl != "print"}/>
           {(splitUrl != "print" && level == "national") ?
           <div className="btn-flex">
              <Button variant="contained" size="medium" className="req" onClick={handleClick}>
                  Approve Request
                </Button>
              <Button variant="contained" size="medium" className="req canc" onClick={handleCancelClick}>
                  Cancel Request
                </Button>
            </div>
            : ""}
        </MDBox>
        </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default IdCardView;
