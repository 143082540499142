import "./style.css"
import { useState, useEffect } from "react";
import * as React from 'react';

import BasicLayout from "layouts/authentication/components/BasicLayout";
export default function MemberPublicProfile() {
console.log("ssssssss")
  const id = window.location.href.split("=")[1];
console.log("connnnnnnnnnnnnnn", id);
  const [member, setMember] = useState(null);
  const [image, setImage] = useState("https://bootdey.com/img/Content/avatar/avatar7.png");
  useEffect(() => {
    fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/members/${id}`)
    .then(x => x.json()).then(response => 
      {
        console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn")
        console.log(response);
        setMember(response.data);
      })
  }, []);
  return (
   
    (member != null )?(
      <BasicLayout>
    <div class="container">
       
    <div class="main-body">
        <div class="row gutters-sm conte">
          <div class="col-md-8">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Full Name</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                  {member.surname} { member.firstName }
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Email</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.email}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Phone number</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.phoneNo}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Jamaat</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.jamaatName}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Circuit</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.circuitName}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Auxiliary Body</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.auxillaryBodyName}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Sex</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.sex}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Address</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.address}
                  </div>
                </div>
                <hr/>
              </div>
            </div>

           
          </div>
        </div>

      </div>
      </div>
      </BasicLayout>) : (<div>Loading...</div>)
      
     );
}