import MaterialTable from "material-table";
import LongMenu from '../../components/ThreeDotsMenu/LongMenu';
import Chip from '@mui/material/Chip';
import "./style.css"
function Members() {

  const columns = [
    {title: "Membership No.", field:"chandaNo"},
    {title: "Surname", field:"surname"},
    {title: "First name", field:"firstName"},
    {title: "Jamaat", field:"jamaatName"},
    {title: "Circuit", field:"circuitName"},
    {title: "Status", field:"cardStatus", render:rowData => {
      if(rowData.cardStatus == "Cardless")
      {
        return (<Chip label="Cardless" variant="outlined" color="error" /> )
      }
      else if(rowData.cardStatus == "Initialized")
      {
        return (
          <Chip label="Initialized" variant="outlined" color="warning" />
       )
      }
      else
      {
        return (<Chip label="Active" variant="outlined" color="success" /> )
      
      }
      
    }},
    {title: "Actions", render:rowData => <LongMenu  id={rowData.chandaNo} actions={[rowData.cardStatus]} />},
  ];
  return (
    <div className="app">
      <div className="container">
        <MaterialTable
        title="Members"
        columns = {columns}
        options={{ debounceInterval: 20, padding: "dense" }}
        data={(query) => new Promise((resolve, reject) => {
          let url = "https://cardapi.ahmadiyyanigeria.net/api/v1/members/members?";
          const level = localStorage.getItem("level");
          const divId = localStorage.getItem("divId");
          if(level == "circuit")
          {
            url = `https://cardapi.ahmadiyyanigeria.net/api/v1/members/circuit/${divId}/members?`
          }
          else if(level == "jamaat")
          {
            url = `https://cardapi.ahmadiyyanigeria.net/api/v1/members/${divId}/eligible?`
          }
          url += `PageNumber=${query.page + 1}&PageSize=${query.pageSize}`
          if (query.search) {
            url += `&Keyword=${query.search}`
          }
          fetch(url).then(resp => resp.json()).then(resp => {
              console.log(url)
              console.log(resp)
              console.log(query)
              resolve({
                data:resp.data.data,
                page: query.page,
                totalCount: resp.data.totalCount
              })
          })
        })
        }
         />
      </div>
    </div>
  );
}
export default Members;