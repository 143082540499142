
// AMJN E-CARD  React layouts
import Dashboard from "layouts/dashboard";
import Members from "layouts/tables";
import ActiveCards from "layouts/tables/activeCards";
import PrintedCards from "layouts/tables/printedCards";
import CardRequests from "layouts/tables/cardRequests";
import SignIn from "layouts/authentication/sign-in";
import CreateRole from "layouts/authentication/add-role";
import SignUp from "layouts/authentication/sign-up";
import IdCardView from "layouts/profile/idcard";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Members",
    key: "members",
    route: "/members",
    icon: <Icon fontSize="small">table_view</Icon>,
    component: <Members />,
  },
  {
    type: "collapse",
    name: "CardRequests",
    key: "cardRequests",
    route: "/cardrequests",
    icon: <Icon fontSize="small">table_view</Icon>,
    component: <CardRequests/>,
  },
  {
    type: "collapse",
    name: "Active Cards",
    key: "activecard",
    route: "/activecard",
    icon: <Icon fontSize="small">table_view</Icon>,
    component: <ActiveCards/>,
  },
  {
    type: "collapse",
    name: "Printed Cards",
    key: "printedCards",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/printedcards",
    component: <PrintedCards/>,
  },
  {
    type: "collapse",
    name: "Create Role",
    key: "add-role",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/authentication/add-role",
    component: <CreateRole />,
  },
  {
    type: "collapse",
    name: "Log out",
    key: "log-out",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
