import MaterialTable from "material-table";
import LongMenu from '../../components/ThreeDotsMenu/LongMenu';
import Chip from '@mui/material/Chip';
import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
import { useState } from "react";
import "./style.css"
function PrintedCards() {
  
  const errorMessage = "No printed cards available"
  const [errorSB, setErrorSB] = useState(false);
  const closeErrorSB = () => setErrorSB(false);
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="AMJN E-CARD"
      content= {errorMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const columns = [
    {title: "Tracking Number", field:"trackingNumber"},
    {title: "Last name", field:"lastName"},
    {title: "First name", field:"firstName"},
    {title: "Jamaat", field:"jamaatName"},
    {title: "Circuit", field:"circuitName"},
    {title: "Status", field:"cardStatus", render:rowData => {
      return (<Chip label="Active" variant="outlined" color="success" />  )
      
    }},
    {title: "Actions", render:rowData => <LongMenu  id={rowData.memberNumber} actions={[rowData.cardStatus]} />},
  ];
  return (
    <div className="app">
      <Grid item xs={12} sm={6} lg={3}>
        {renderErrorSB}
      </Grid>
      <div className="container">
        <MaterialTable
        title="Members"
        columns = {columns}
        data={(query) => new Promise((resolve, reject) => {
          
          let url = "https://cardapi.ahmadiyyanigeria.net/api/v1/cards/allprintedcards?";
          const level = localStorage.getItem("level");
          const divId = localStorage.getItem("divId");
          if(level == "circuit")
          {
            url = `https://cardapi.ahmadiyyanigeria.net/api/v1/cards/getprintedcardsbycircuit/${divId}?`
          }
          else if(level == "jamaat")
          {
            url = `https://cardapi.ahmadiyyanigeria.net/api/v1/cards/getprintedcardsbyjamaat/${divId}?`
          }
          url += `PageNumber=${query.page + 1}&PageSize=${query.pageSize}`
          fetch(url).then(resp => resp.json()).then(resp => {
              console.log(resp)
              if(resp.data.data.length == 0)
              {
                setErrorSB(true);
              }
              console.log(query)
              resolve({
                data:resp.data.data,
                page: query.page,
                totalCount: resp.data.totalCount
              })
          })
        })
        }
         />
      </div>
    </div>
  );
}
export default PrintedCards;