export const fetchLogin = async (data) => {
    const settings = {

        method: "POST",
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
    
      };
      const login = await fetch("https://cardapi.ahmadiyyanigeria.net/api/auth/token", settings);
      return login.json();
}
export const fetchLoggedInMember = async (memberNo) => {
      const member = await fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/members/${memberNo}`);
      return member.json();
}