
import { useState } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
import CircularProgress from '@mui/material/CircularProgress';
import "./style.css"

// AMJN E-CARD  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "./background.png";
import {fetchLogin, fetchLoggedInMember} from "./fetchLogin"
function Basic() {
  
  const succesMessage = "Login successfully"
  const errorMessage = "You are not authorised to access the site"
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

const closeErrorSB = () => setErrorSB(false);
const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="AMJN E-CARD"
      content= {succesMessage}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="AMJN E-CARD"
      content= {errorMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const handleClick = async () => {
    setSignIn(<CircularProgress color="inherit"/>)
    const userName = document.querySelector("#userName")
    const password = document.querySelector("#password")
    const userNameValue = userName.value;
    const passwordValue = password.value;
    const data = {
      "userName": userNameValue,
      "password": passwordValue
    };
    const login = await fetchLogin(data);
    console.log(login.data);
    
    if(login.status)
    {
      const loggedMember = await fetchLoggedInMember(login.data.userName);
      console.log(loggedMember);
      const jamaat = login.data.roles.some(x => x.toLowerCase().includes("jamaat"));
      const circuit = login.data.roles.some(x => x.toLowerCase().includes("circuit"));
      const national = login.data.roles.some(x => x.toLowerCase().includes("national"));
     
      if(national)
      {
        localStorage.setItem("level", "national")
        
      }
      else if(circuit){
        localStorage.setItem("level", "circuit")
        localStorage.setItem("divId", loggedMember.data.circuitId)
      }
      else if(jamaat){
        localStorage.setItem("level", "jamaat")
        localStorage.setItem("divId", loggedMember.data.jamaatId)
      }
      
      if(jamaat || circuit || national)
      {
        setSuccessSB(true)
        window.location.href = "/dashboard"
        return;
      }
      setErrorSB(true);
    }
    else{
      setSignIn("signin")
      setErrorSB(true);
    }
  }

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const[signIn, setSignIn] = useState("signin")
  return (
  
    <BasicLayout>
      <Card>
      <Grid item xs={12} sm={6} lg={3}>
        {renderSuccessSB}
        {renderErrorSB}
      </Grid>
        <MDBox
          variant="gradient"
          bgColor="success"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img id="logo-img" src={bgImage}></img>
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            AMJN E-CARD
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" id="userName" label="User Name" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" id="password" label="Password" fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="success" fullWidth onClick={handleClick}>
               {signIn}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
