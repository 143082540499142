import "./style.css"
import { useState, useEffect } from "react";
import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';


import Grid from "@mui/material/Grid";
import MDSnackbar from "components/MDSnackbar";
export default function PersonalProfile() {

  
  const succesMessage = "Successfully made request"
  const [errorMessage, setErrorMessage] = useState("Update this member profile from the jamaat portal");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

const closeErrorSB = () => setErrorSB(false);
const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="AMJN E-CARD"
      content= {succesMessage}
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="AMJN E-CARD"
      content= {errorMessage}
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );


    const id = window.location.href.split("=")[1];
  const [member, setMember] = useState(null);
  const [image, setImage] = useState("https://bootdey.com/img/Content/avatar/avatar7.png");
  useEffect(() => {
    fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/members/${id}`)
    .then(x => x.json()).then(response => 
      {
        console.log(response);
        setMember(response.data);
      })
  }, []);


  let handleClick = async () => {
    let form = document.querySelector("#form");
    let name = document.querySelector("#name");
    console.log(name.innerText)
    let sendForm = new FormData(form);
    const settings = {
      method: 'POST',
      body:sendForm
  };
  try {
    let getRequest = document.querySelector("#requestReason");
    // var value = getRequest.value;
    let value = getRequest.options[getRequest.selectedIndex].value;
    let getimage = document.querySelector("#image");
    const fetchResponse = await fetch(`https://cardapi.ahmadiyyanigeria.net/api/v1/cards/requestcard/${value}`, settings);
    if(fetchResponse.status == "200")
    {
      setSuccessSB(true)
      window.location.href = "/members"
    }
    else{
      
      if(getRequest.value == "")
      {
        console.log("From card test...", typeof getRequest.value,  typeof getimage.value, "From card request test...")
        setErrorMessage("Pls fill the request reason input");
      }
      else if(getimage.value == "")
      {
        setErrorMessage("Pls select image for the member");
      }
      setErrorSB(true);
    }
    
} catch (e) {
  console.log(e);
    return e;
}    
  }
  return (
    (member != null )?(
    <div class="container">
       <Grid item xs={12} sm={6} lg={3}>
        {renderSuccessSB}
        {renderErrorSB}
      </Grid>
    <div class="main-body">
        <div class="row gutters-sm conte">
          <div class="col-md-4 mb-3">
            <div class="card">
              <form class="card-body" id="form">
                <div class="d-flex flex-column align-items-center text-center">
                  <img src={image} alt="Admin" class="rounded-circle" width="150"/>
                  <IconButton color="primary" aria-label="upload picture" component="label">
                      <input id="image" name="image" hidden accept="image/*" onChange={(event) => setImage(window.URL.createObjectURL(event.target.files[0]))} type="file" />
                      <PhotoCamera color="success" />
                    </IconButton>
                  <div class="mt-3">
                    <h4 id="name"> {member.surname} { member.firstName }</h4>
                    <p class="text-secondary mb-1"></p>
                    <input class="text-muted font-size-sm" name="memberNo" value={member.chandaNo} readOnly style={
                     { border:"none"}
                    }/>
                    <Button variant="contained" size="medium" className="req" onClick={handleClick}>
                      Send Request
                  </Button>
                  </div>
                </div>
              </form>
            </div>
            
          </div>
          <div class="col-md-8">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Full Name</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                  {member.surname} { member.firstName }
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Email</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.email}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Phone number</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.phoneNo}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Jamaat</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.jamaatName}
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Address</h6>
                  </div>
                  <div class="col-sm-9 text-secondary">
                    {member.address}
                  </div>
                </div>
                <hr/>
                <div class="row">
                <div class="col-sm-3">
                    <h6 class="mb-0">Request reason</h6>
                  </div>
                  <select id="requestReason">
                        <option value="First Request">First Request</option>
                        <option value="ReIssue">Reissuing due to lost or expiry</option>
                  </select>
                  {/* <textarea class="col-sm-9 text-secondary" id="requestReason" rows="5" cols="20"></textarea> */}
                </div>
              </div>
            </div>

           
          </div>
        </div>

      </div>
      </div>) : (<div>Nothing to display</div>)
  );
}