/**
=========================================================
* AMJN E-CARD  React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 AMJN (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';
// AMJN E-CARD  React components
import MDBox from "components/MDBox";

// AMJN E-CARD  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useState } from 'react'

// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import "./style.css"
function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [counts, setCounts] = useState(null);
  let activePercentage = 0;
  let cardRequestPercentage = 0;
  let cardLessMembersPercentage = 0;
  
  
    useEffect(() => {
        let url = `https://cardapi.ahmadiyyanigeria.net/api/dashboard/getcounts`;
        const level = localStorage.getItem("level");
        const divId = localStorage.getItem("divId");
        if(level == "circuit")
        {
          url = `https://cardapi.ahmadiyyanigeria.net/api/dashboard/circuit/getcounts/${divId}`
        }
        else if(level == "jamaat")
        {
          url = `https://cardapi.ahmadiyyanigeria.net/api/dashboard/jamaat/getcounts/${divId}`
        }
            fetch(url)
            .then(x => x.json()).then(response => 
            {
                console.log(response);
                setCounts(response.data);
            });
    }, []);
  if(counts != null)
  {
    activePercentage = ((counts.activeCardsCount/counts.allMembers) * 100) + "%";
    cardRequestPercentage = ((counts.cardRequestCount/counts.allMembers) * 100) + "%";
    cardLessMembersPercentage = ((counts.cardlessMembersCount/counts.allMembers) * 100) + "%";
  }
   console.log(activePercentage);
  return ( (counts != null) ? (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="checkbox"
                title="Active Cards"
                count={counts.activeCardsCount}
                percentage={{
                  color: "success",
                  amount: "55.5%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="verifieduser"
                title="Approved Card Requests"
                count= {counts.inActiveCardsCount}
                percentage={{
                  color: "success",
                  amount: "55.5%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="print"
                title="Printed cards"
                count={counts.printedCardsCount}
                percentage={{
                  color: "success",
                  amount: `${0.00000078}%`,
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="group"
                title="All Members"
                count= {counts.allMembers}
                percentage={{
                  color: "success",
                  amount: "55.5%",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="cancel"
                title="Rejected Cards"
                count={counts.rejectedCardRequestCount}
                percentage={{
                  color: "success",
                  amount: "55.5%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                color="warning"
                title="Pending Requests"
                count= {counts.initializedCardRequestCount}
                percentage={{
                  color: "success",
                  amount: "55.5%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="close"
                title="Inactie cards"
                count={counts.inActiveCardsCount}
                percentage={{
                  color: "success",
                  amount: `${0.00000078}%`,
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="All Members"
                count= {counts.allMembers}
                percentage={{
                  color: "success",
                  amount: "55.5%",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>) : <CircularProgress color="secondary" />
  );
}

export default Dashboard;
